.components-foot{
  display: block;
  white-space: nowrap;
  background-color: #F6F9FF;
  a{
    color: inherit;
    &:hover{
      color: #317AFC;
    }
  }
  .foot-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 88px 122px 0;
    .foot-list{
      display: flex;
      align-items: flex-start;
      .item{
        margin-right: 200px;
        &.small{
          margin-right: 150px;
        }
        &.second{
          margin-right: 110px;
        }
        div{
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 22px;
          margin-top: 24px;
        }
        .title{
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #000000;
          height: 28px;
          line-height: 28px;
          margin-top: 0;
          margin-bottom: 32px;
        }
        &.second {
          div{
            margin-top: 16px;
          }
          .title{
            font-size: 14px;
            font-family: Roboto-ExtraBold, Roboto;
            font-weight: 800;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .logo{
          width: 137px;
        }
        .font-bold{
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .content-title{
      margin-top: 49px;
      padding-top: 47px;
      padding-bottom: 32px;
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      border-top: 1px solid #6F7D8F;
    }
    .qr-code{
      width: 121px;
      height: 121px;
      background: #FFFFFF;
      box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img{
        width: 105px;
        height: 105px;
      }
    }
    .link{
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 28px;
      margin-top: 28px;
    }
  }
  .icp{
    width: 100%;
    height: 90px;
    line-height: 90px;
    text-align: center;
    background: #EEF4FF;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #000000;
  }
}
.components-foot-mobile{
  display: none;
}
.swiper-slide > .components-foot {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

@media screen and(max-width: 1600px) {
  .components-foot{
    .foot-content{
      padding: 73px 102px;
      .foot-list{
        .item{
          margin-right: 167px;
          &.small{
            margin-right: 125px;
          }
          &.second{
            margin-right: 91px;
          }
          div{
            font-size: 12px;
            line-height: 18px;
            margin-top: 20px;
          }
          .title{
            font-size: 15px;
            height: 23px;
            line-height: 23px;
            margin-bottom: 27px;
          }
          &.second {
            div{
              margin-top: 13px;
            }
            .title{
              font-size: 12px;
              line-height: 18px;
            }
          }
          .logo{
            width: 115px;
          }
        }
      }
      .content-title{
        margin-top: 41px;
        padding-top: 39px;
        padding-bottom: 27px;
        font-size: 15px;
        line-height: 23px;
      }
      .qr-code{
        width: 101px;
        height: 101px;
        box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        margin-top: 27px;
        img{
          width: 88px;
          height: 88px;
        }
      }
      .link{
        font-size: 13px;
        line-height: 23px;
        margin-top: 40px;
      }
    }
    .icp{
      height: 100px;
      line-height: 100px;
      font-size: 13px;
    }
  }
}

@media screen and(max-width: 1440px) {
  .components-foot{
    .foot-content{
      padding: 66px 92px;
      .foot-list{
        .item{
          margin-right: 150px;
          &.small{
            margin-right: 113px;
          }
          &.second{
            margin-right: 82px;
          }
          div{
            font-size: 11px;
            line-height: 17px;
            margin-top: 18px;
          }
          .title{
            font-size: 14px;
            height: 21px;
            line-height: 21px;
            margin-bottom: 24px;
          }
          &.second {
            div{
              margin-top: 12px;
            }
            .title{
              font-size: 11px;
              line-height: 17px;
            }
          }
          .logo{
            width: 103px;
          }
        }
      }
      .content-title{
        margin-top: 37px;
        padding-top: 35px;
        padding-bottom: 24px;
        font-size: 14px;
        line-height: 21px;
      }
      .qr-code{
        width: 91px;
        height: 91px;
        box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        margin-top: 24px;
        img{
          width: 79px;
          height: 79px;
        }
      }
      .link{
        font-size: 12px;
        line-height: 21px;
        margin-top: 36px;
      }
    }
    .icp{
      height: 90px;
      line-height: 90px;
      font-size: 12px;
    }
  }
}

@media screen and(max-width: 1200px) {
  .components-foot{
    .foot-content{
      padding: 55px 76px;
      .foot-list{
        .item{
          margin-right: 105px;
          &.small{
            margin-right: 74px;
          }
          &.second{
            margin-right: 68px;
          }
          div{
            line-height: 14px;
            margin-top: 15px;
          }
          .title{
            height: 18px;
            line-height: 18px;
            margin-bottom: 20px;
          }
          &.second {
            div{
              margin-top: 10px;
            }
            .title{
              line-height: 14px;
            }
          }
          .logo{
            width: 86px;
          }
        }
      }
      .content-title{
        margin-top: 31px;
        padding-top: 29px;
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
      }
      .qr-code{
        width: 76px;
        height: 76px;
        box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        margin-top: 20px;
        img{
          width: 66px;
          height: 66px;
        }
      }
      .link{
        font-size: 10px;
        line-height: 18px;
        margin-top: 30px;
      }
    }
    .icp{
      height: 75px;
      line-height: 75px;
      font-size: 10px;
    }
  }
}

@media screen and(max-width: 992px) {
  .components-foot{
    .foot-content{
      padding: 45px 40px;
      .foot-list{
        .item{
          margin-right: 70px;
          &.small{
            margin-right: 50px;
          }
          &.second{
            margin-right: 56px;
          }
          div{
            margin-top: 12px;
          }
          .title{
            margin-bottom: 17px;
          }
          &.second {
            div{
              margin-top: 8px;
            }
          }
          .logo{
            width: 71px;
          }
        }
      }
      .content-title{
        margin-top: 25px;
        padding-top: 24px;
        padding-bottom: 17px;
      }
      .qr-code{
        width: 63px;
        height: 63px;
        margin-top: 17px;
        img{
          width: 54px;
          height: 54px;
        }
      }
      .link{
        margin-top: 25px;
      }
    }
    .icp{
      height: 62px;
      line-height: 62px;
    }
  }
}

@media screen and(max-width: 760px) {
  .components-foot{
    display: none !important;
  }
  .components-foot-mobile{
    display: block;
    white-space: nowrap;
    background-color: #F6F9FF;
    a{
      color: inherit;
      display: block;
    }
    .rotate{
      transform: rotate(180deg);
    }
    .ant-collapse-borderless{
      background-color: transparent;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      border-bottom-color: #E7E7E7;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 20px 24px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      display: flex;
      align-items: center;
      &>div:first-child{
        width: 16px;
        height: 16px;
      }
      .ant-collapse-arrow{
        width: 100%;
        height: 100%;
        transition: all 0.2s linear;
      }
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
      padding: 0;
      .ant-collapse-content-box {
        padding: 0;
        &>div>a{
          padding: 20px 24px;
          border-top: 1px solid #E7E7E7;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #6F7D8F;
          line-height: 24px;
        }
      }
    }
    .ant-collapse > .ant-collapse-item.no-arrow .ant-collapse-header .ant-collapse-arrow{
      display: none;
    }
    .icp{
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #6F7D8F;
      line-height: 24px;
      text-align: center;
      a{
        display: inline-block;
      }
      div:nth-child(2) {
        margin-top: 12px;
        white-space: normal;
      }
    }
    .contact-us{
      padding-top: 64px;
      padding-bottom: 64px;
      background: #FFFFFF;
      box-shadow: 0 6px 5px 1px rgba(0, 0, 0, 0.04);
      text-align: center;
      img{
        width: 32%;
      }
      span{
        display: block;
        font-size: 16px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
        margin-top: 24px;
      }
    }
  }
  .swiper-slide > .components-foot-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-top: 40px;
    .contact-us{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 0;
      span {
        margin-top: 15px;
      }
    }
    .ant-collapse{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 15px 24px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-content {
      .ant-collapse-content-box {
        & > div > a {
          padding: 12px 24px;
        }
      }
    }
    .icp {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}
