.container-NewsDetail{
  margin-left: 122px;
  margin-right: 122px;
  padding-bottom: 88px;
  .content{
    margin-top: 64px;
    margin-left: 142px;
    margin-right: 142px;
  }
  .title{
    font-size: 32px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #191B1E;
    line-height: 42px;
  }
  .date{
    height: 42px;
    font-size: 18px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 42px;
    margin-top: 16px;
  }
  .line{
    height: 1px;
    background-color: #E7E7E7;
    margin: 16px 0 56px 0;
  }
  .text{
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 48px;
  }
  .img{
    display: block;
    width: 100%;
    background-color: #DDD;
    vertical-align: bottom;
  }
  .text,
  .img{
    margin-top: 48px;
  }
}

@media screen and(max-width: 1600px) {
  .container-NewsDetail{
    margin-left: 102px;
    margin-right: 102px;
    padding-bottom: 73px;
    .content{
      margin-top: 53px;
      margin-left: 116px;
      margin-right: 116px;
    }
    .title{
      font-size: 27px;
      line-height: 35px;
    }
    .date{
      height: 35px;
      font-size: 15px;
      line-height: 35px;
      margin-top: 13px;
    }
    .line{
      margin: 13px 0 47px 0;
    }
    .text{
      font-size: 20px;
      line-height: 40px;
    }
    .text,
    .img{
      margin-top: 40px;
    }
  }
}

@media screen and(max-width: 1440px) {
  .container-NewsDetail{
    margin-left: 92px;
    margin-right: 92px;
    padding-bottom: 66px;
    .content{
      margin-top: 48px;
      margin-left: 104px;
      margin-right: 104px;
    }
    .title{
      font-size: 24px;
      line-height: 31px;
    }
    .date{
      height: 32px;
      font-size: 14px;
      line-height: 31px;
      margin-top: 12px;
    }
    .line{
      margin: 12px 0 42px 0;
    }
    .text{
      font-size: 18px;
      line-height: 36px;
    }
    .text,
    .img{
      margin-top: 36px;
    }
  }
}

@media screen and(max-width: 1200px) {
  .container-NewsDetail{
    margin-left: 76px;
    margin-right: 76px;
    padding-bottom: 55px;
    .content{
      margin-top: 40px;
      margin-left: 88px;
      margin-right: 88px;
    }
    .title{
      font-size: 20px;
      line-height: 26px;
    }
    .date{
      height: 26px;
      font-size: 11px;
      line-height: 26px;
      margin-top: 10px;
    }
    .line{
      margin: 10px 0 35px 0;
    }
    .text{
      font-size: 15px;
      line-height: 30px;
    }
    .text,
    .img{
      margin-top: 30px;
    }
  }
}

@media screen and(max-width: 992px) {
  .container-NewsDetail{
    margin-left: 63px;
    margin-right: 63px;
    padding-bottom: 45px;
    .content{
      margin-top: 33px;
      margin-left: 72px;
      margin-right: 72px;
    }
    .title{
      font-size: 17px;
      line-height: 22px;
    }
    .date{
      height: 22px;
      font-size: 9px;
      line-height: 22px;
      margin-top: 8px;
    }
    .line{
      margin: 8px 0 29px 0;
    }
    .text{
      font-size: 12px;
      line-height: 25px;
    }
    .text,
    .img{
      margin-top: 25px;
    }
  }
}

@media screen and(max-width: 760px) {
  .container-NewsDetail{
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 40px;
    .content{
      margin-top: 32px;
      margin-left: 0;
      margin-right: 0;
    }
    .title{
      font-size: 16px;
      line-height: 24px;
    }
    .date{
      height: 22px;
      font-size: 14px;
      line-height: 22px;
      margin-top: 8px;
    }
    .line{
      display: none;
    }
    .text{
      font-size: 14px;
      line-height: 28px;
    }
    .text,
    .img{
      margin-top: 16px;
    }
  }
}
