.position-table-container {
  margin: 48px 6.35% 0;
  padding-bottom: 80px;
  &.h5 {
    display: none;
  }
  .table-title {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba($color: #000000, $alpha: 0.6);
    line-height: 22px;
    .classfily {
      color: #317afc;
    }
  }
  .table {
    margin-top: 16px;
    .title {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 22px;
    }
    .table-col {
      display: flex;
      .border {
        border: 1px solid #e7e7e7;
        height: 42px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        padding-right: 50px;
        width: 11.571%;
        margin: -1px -1px 0 0;
        &:first-child {
          flex: 1;
        }
        &:last-child,
        &:nth-last-child(2) {
          width: 20.225%;
        }
      }
    }
    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border: none;
        background: #fff;
        .ant-collapse-header {
          // background: red;
          padding: 0;
          box-sizing: content-box;
          .table-col {
            width: 100%;
            .border{
              position: relative;
              .hot-img-pc{
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
              .icon-down{
                position: absolute;
                right: 26px;
                top: 50%;
                transform: translate(0, -50%);
                color: #000000;
                transition: all .3s;
              }
            }
            
          }
        }

        &.ant-collapse-item-active {
          background: #317afc;
          .ant-collapse-header {
            .table-col {
              .border {
                border: 1px solid #317afc;
                color: #fff;
                .icon-down{
                  color: #fff;
                  transform: translate(0, -50%) rotate(180deg) ;
                }
              }
            }
          }
        }
        .ant-collapse-content {
          border: 1px solid #e7e7e7;
          border-top: none;
          width: calc(100% + 1px);
          .ant-collapse-content-box {
            padding-bottom: 48px;
          }
        }
      }
    }
    .pc-title{
      font-size: 18px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .job-list {
      margin-top: 24px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
    }
    .jump-btn {
      margin-top: 24px;
      width: 104px;
      height: 48px;
      background: #317afc;
    }
  }
}
@media screen and (max-width: 1310px) {
  .position-table-container {
    &.pc{
      display: none;
    }
    &.h5 {
      display: block;
    }
    margin: 32px 24px 0;
    padding-bottom: 80px;
    .table-title {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 22px;
      margin-bottom: 16px;
      .classfily {
        color: #317afc;
      }
    }
    .h5-collapse {
      background: #fff;
      .ant-collapse-item {
        border: 1px solid #f3f3f3;
        &:not(:first-child) {
          margin-top: 16px;
        }
        .ant-collapse-header {
          justify-content: space-between;
          padding: 24px 24px 16px 24px;
          flex-direction: row-reverse;
          .h5-panel {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #191b1e;
            line-height: 24px;
            display: flex;
            align-items: center;
            .hot-img-h5{
              width: 12px;
              height: 12px;
              margin-right: 6px;
            }
          }
        }
        .ant-collapse-content {
          padding: 0 24px;
          .ant-collapse-content-box {
            padding: 0 0 24px 0;
            .item-content {
              border-bottom: 1px solid #f3f3f3;
              .item {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.9);
                line-height: 22px;
                padding-bottom: 16px;
                // &:last-child{
                // }
              }
            }

            .jobres-h5 {
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #000000;
              line-height: 28px;
              margin-top: 16px;
            }
            .jobspe-h5 {
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #000000;
              line-height: 28px;
              margin-top: 16px;
            }
            .jump-btn-h5{
              width: 100%;
              height: 48px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #FFFFFF;
              margin-top: 16px;
              background: #317AFC;
            }
          }
        }
      }
    }
  }
}
