$h: 72px;
$hb:40px;
.header{
  position: fixed;
  top: 0;
  width: 100%;
  height: $h;
  line-height: $h;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  padding: 0 122px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  //background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  .logos{
    display: flex;
    align-items: center;
  }
  .logo{
    width:103px;
    height: 24px;
  }
  .header-nav{
    display: flex;
    justify-content: space-between;
    a{
      display: block;
      color: rgba(255, 255, 255, 0.6);
      padding: 0 24px;
      position: relative;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      &:last-of-type{
        margin-right: 40px;
      }
      &::after{
        width: 0;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      &:hover, &.active{
        color: #ffffff;
        &::after{
          width: 100%;
          height: 1px;
          background: #ffffff;
          -webkit-transition: width .5s;
          -moz-transition: width .5s;
          -ms-transition: width .5s;
          -o-transition: width .5s;
          transition: width .5s;
        }
      }
    }
    .change-lan{
      position: relative;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      padding-left: 64px;
      font-size: 14px;
      span{
        display: block;
        cursor: pointer;
        text-align:center;
        position: relative;
        &:hover{
          color: #ffffff;
        }
      }
      &::before{
        position: absolute;
        left: 0;
        top: 24px;
        content: '';
        width: 1px;
        height: 24px;
        background: rgba(255, 255, 255, 0.3);;
      }
    }
  }
}


.header2{
  position: fixed;
  top: 0;
  width: 100%;
  height: $h;
  line-height: $h;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  padding: 0 122px;
  border-bottom: 1px solid #E7E7E7;
  background: #F6F9FF;
  .logos{
    display: flex;
    align-items: center;
  }
  .logo{
    width:103px;
    height: 24px;
  }
  .header-nav{
    display: flex;
    justify-content: space-between;
    a{
      display: block;
      color: rgba(0, 0, 0, 0.6);
      padding: 0 24px;
      position: relative;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      &:last-of-type{
        margin-right: 40px;
      }
      &::after{
        width: 0;
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      &:hover, &.active{
        color: #317AFC;
        &::after{
          width: 100%;
          height: 1px;
          background: #317AFC;
          -webkit-transition: width .5s;
          -moz-transition: width .5s;
          -ms-transition: width .5s;
          -o-transition: width .5s;
          transition: width .5s;
        }
      }
    }
    .change-lan{
      position: relative;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      padding-left: 64px;
      font-size: 14px;
      span{
        display: block;
        cursor: pointer;
        text-align:center;
        position: relative;
        &:hover{
          color: #317AFC;
        }
      }
      &::before{
        position: absolute;
        left: 0;
        top: 24px;
        content: '';
        width: 1px;
        height: 24px;
        background: rgba(255, 255, 255, 0.3);;
      }
    }
  }
}
.header-mobile{
  display: none;
}
@media screen and(max-width: 1020px) {
  .header{
    padding: 0 30px;
  }
  .header2{
    padding: 0 30px;
  }
}
@media screen and(max-width: 850px) {
  .header{
    height: $hb;
    line-height: $hb;
    .logo{
      width:69px;
      height: 16px;
    }
    .header-nav{
      display: none;
    }
    .nav{
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 12px;
    }
    .header-mobile{
      display: block;
      position: relative;
      z-index: 9999;
    }
    .header-nav-mobile{
      display: none;
      position: absolute;
      height: 0;
      width: 102px;
      top: 44px;
      right: 24px;
      background: #FFFFFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      a{
        display: block;
        width: 100%;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #000000;
        line-height: 48px;
        text-align: center;
      }
      .active{
        color: #40a9ff;
      }
    }
    .header-nav-mobile.shows{
      height: auto;
      display: block;
      -webkit-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -moz-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -ms-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -o-transition: height 0s 500ms, opacity 300ms ease-in-out;
      transition: height 0s 500ms, opacity 300ms ease-in-out;
    }
  }
  .header2{
    height: $hb;
    line-height: $hb;
    .logo{
      width:69px;
      height: 16px;
    }
    .header-nav{
      display: none;
    }
    .nav{
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 12px;
    }
    .header-mobile{
      display: block;
      position: relative;
      z-index: 9999;
    }
    .header-nav-mobile{
      display: none;
      position: absolute;
      height: 0;
      width: 102px;
      top: 44px;
      right: 24px;
      background: #FFFFFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      a{
        display: block;
        width: 100%;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #000000;
        line-height: 48px;
        text-align: center;
      }
    }
    .header-nav-mobile.shows{
      height: auto;
      display: block;
      -webkit-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -moz-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -ms-transition: height 0s 500ms, opacity 300ms ease-in-out;
      -o-transition: height 0s 500ms, opacity 300ms ease-in-out;
      transition: height 0s 500ms, opacity 300ms ease-in-out;
    }
  }

}
