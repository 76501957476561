.components-head-img{
  position: relative;
  .head-img-pc,
  .head-img-mobile {
    display: block;
    width: 100%;
    vertical-align: bottom;
  }
  .head-img-mobile{
    display: none;
  }
  .head-img-text{
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    text-align: center;
    .head-img-title{
      font-size: 32px;
      font-family: Roboto-Bold, Lato;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
      letter-spacing: 22px;
      padding-left: 22px;
    }
    .head-img-titleTranslate{
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      letter-spacing: 4px;
      padding-left: 4px;
      margin-top: 8px;
      margin-left: auto;
      margin-right: auto;
      &.line-light,
      &.line-light-short{
        letter-spacing: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before,
        &:after {
          content: '';
          display: inline-block;
          width: 72px;
          height: 2px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 52%, rgba(255, 255, 255, 0) 100%);
          margin-left: 4px;
          margin-right: 4px;
        }
      }
      &.line-light-short:before,
      &.line-light-short:after{
        width: 28px;
      }
    }
  }
}

@media screen and(max-width: 1600px) {
  .components-head-img{
    .head-img-text{
      .head-img-title{
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 18px;
        padding-left: 18px;
      }
      .head-img-titleTranslate{
        font-size: 12px;
        line-height: 23px;
        letter-spacing: 3px;
        padding-left: 3px;
        margin-top: 7px;
        &.line-light{
          &:before,
          &:after {
            width: 60px;
            height: 2px;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
        &.line-light-short:before,
        &.line-light-short:after{
          width: 23px;
        }
      }
    }
  }
}

@media screen and(max-width: 1440px) {
  .components-head-img{
    .head-img-text{
      .head-img-title{
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 17px;
        padding-left: 17px;
      }
      .head-img-titleTranslate{
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 3px;
        padding-left: 3px;
        margin-top: 6px;
        &.line-light{
          &:before,
          &:after {
            width: 54px;
            height: 2px;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
        &.line-light-short:before,
        &.line-light-short:after{
          width: 21px;
        }
      }
    }
  }
}

@media screen and(max-width: 1200px) {
  .components-head-img{
    .head-img-text{
      .head-img-title{
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 14px;
        padding-left: 14px;
      }
      .head-img-titleTranslate{
        margin-top: 5px;
        &.line-light{
          &:before,
          &:after {
            width: 45px;
          }
        }
        &.line-light-short:before,
        &.line-light-short:after{
          width: 18px;
        }
      }
    }
  }
}

@media screen and(max-width: 992px) {
  .components-head-img{
    .head-img-text{
      .head-img-title{
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 11px;
        padding-left: 11px;
      }
      .head-img-titleTranslate{
        margin-top: 4px;
        &.line-light{
          &:before,
          &:after {
            width: 37px;
          }
        }
        &.line-light-short:before,
        &.line-light-short:after{
          width: 14px;
        }
      }
    }
  }
}

@media screen and(max-width: 760px) {
  .components-head-img{
    position: relative;
    .head-img-pc{
      display: none;
    }
    .head-img-mobile{
      display: block;
    }
    .head-img-text{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-align: center;
      margin-top: 7%;
      .head-img-title{
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 5px;
        padding-left: 5px;
      }
      .head-img-titleTranslate{
        display: inline-block;
        width: 54%;
        font-size: 9px;
        line-height: 16px;
        letter-spacing: 2px;
        padding-left: 2px;
        margin-top: 8px;
        white-space: normal;
        word-wrap: break-word;
        word-break: keep-all;
        text-overflow: initial;
        &.line-light{
          display: inline-block;
          letter-spacing: 2px;
          padding-left: 2px;
          &:before,
          &:after {
            display: none;
          }
        }
        &.line-light-short:before,
        &.line-light-short:after{
          display: none;
        }
      }
    }
  }
}
