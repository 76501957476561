.my-product-container {
  .banner {
    width: 100%;
    >img{
      width: 100%;
    }
  }
  .content-warp {
    display: flex;
    .col-3 {
      width: 25%;
    }
    .col-6 {
      width: 50%;
    }
    .content-left {
      display: flex;
      justify-content: center;
      padding-top: 98px;
      // padding: 98px 3% 0;
      .ant-anchor-wrapper {
        width: 80%;
        max-width: 219px;
        margin: 0;
        padding: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        .ant-anchor {
          padding: 0;
          .ant-anchor-ink {
            display: none !important;
          }
          .ant-anchor-link {
            padding: 0;
            margin: 24px 0;
            height: 48px;
            background: transparent;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 8px 8px 8px 8px;
            >a{
              padding: 0 24px;
            }
            &:not(:first-child) {
              margin: 0 0 8px 0;
            }
            &:hover {
              background: #f3f3f3;
              .ant-anchor-link-title {
                color: rgba(0, 0, 0, 0.6) !important;
              }
            }
            .ant-anchor-link-title {
              font-size: 16px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          .ant-anchor-link-active {
            background: #317afc;
            .ant-anchor-link-title-active {
              color: #ffffff;
            }
          }
        }
      }
    }
    .content-center {
      display: flex;
      flex-direction: column;
      padding: 90px 0 107px 0;
      > div {
        flex: 1;
      }
      .item {
        .title {
          display: flex;
          align-items: center;
          .icon {
            width: 40px;
            height: 40px;
            margin-right: 16px;

            > img {
              width: 100%;
              max-height: 100%;
            }
          }
          .title-font {
            font-size: 40px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #000000;
            line-height: 60px;
          }
        }
        .two-title{
          margin-top: 8px;
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
        }
        .tips{
          margin-top: 24px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 28px;
        }
        .data-list-warp{
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          .data-list{
            display: flex;
            .label{
              margin-right: 8px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #000000;
              line-height: 28px;
            }
            .value{
              font-size: 22px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #317AFC;
              line-height: 28px;
            }
            &:not(:last-child){
              margin-right: 72px;
            }
          }
        }
        .detail-img{
          width: 100%;
          margin-top: 40px;
          >img{
            width: 100%;
            max-height: 100%;
          }
        }
        .down-load{
          margin-top: 16px;
          >span{
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 28px;
          }
          >a{
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #317AFC;
            line-height: 28px;
          }
        }
        &:not(:first-child){
          margin-top: 196px;
        }
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .my-product-container {
    .content-warp {
      // background: red;
      justify-content: center;
      .col-3 {
        // width: 25%;
        display: none;
      }
      .col-6 {
        width: 90%;
      }
      .content-center {
        display: flex;
        flex-direction: column;
        padding: 0 0 107px 0;
        > div {
          flex: 1;
        }
        .item {
          padding-top: 50px;
          .title {
            display: flex;
            align-items: center;
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 8px;
  
              > img {
                width: 100%;
                max-height: 100%;
              }
            }
            .title-font {
              font-size: 18px;
              font-family: Roboto-Bold, Roboto;
              font-weight: bold;
              color: #000000;
              line-height: 28px;
            }
          }
          .two-title{
            margin-top: 8px;
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 22px;
          }
          .tips{
            margin-top: 24px;
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 28px;
          }
          .data-list-warp{
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            .data-list{
              display: flex;
              .label{
                margin-right: 8px;
                font-size: 14px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #000000;
                line-height: 28px;
              }
              .value{
                font-size: 22px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #317AFC;
                line-height: 28px;
              }
              &:not(:last-child){
                margin-right: 72px;
              }
            }
          }
          .detail-img{
            width: 100%;
            margin-top: 40px;
            >img{
              width: 100%;
              max-height: 100%;
            }
          }
          &:not(:first-child){
            margin-top: 50px;
          }
        }
      }
    }
  }
}