#root { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: #F6F9FF;
}
.home-container{

}
.swiper-button-prev{
  display: none;
}
.swiper-button-next{
  top: 95%;
  left: 50%;
  margin-left:-22px;
  background: url('./icon.png') 50% no-repeat;
  background-size: 100%;
  animation: toggleMove .8s infinite;
  -moz-animation: toggleMove 10.8s infinite;
  -webkit-animation: toggleMove .8s infinite;
  -o-animation: toggleMove .8s infinite;
}
@keyframes toggleMove {
  0% {
    bottom: 5.5%;
    opacity: .3
  }

  50% {
    bottom: 4.5%;
    opacity: 1
  }

  to {
    bottom: 5.5%;
    opacity: .3
  }
}

@-webkit-keyframes toggleMove {
  0% {
    bottom: 5.5%;
    opacity: .3
  }

  50% {
    bottom: 4.5%;
    opacity: 1
  }

  to {
    bottom: 5.5%;
    opacity: .3
  }
}
.swiper-button-next.swiper-button-disabled {
  display: none;
}
.swiper-button-next:after, .swiper-button-prev:after{
  display: none;
}
