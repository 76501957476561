.tech-container{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 121px 0;
  .img1{
    position: absolute;
    width: 241px;
    height: 179px;
    left: 0;
    top: 0;
  }
  .img2{
    position: absolute;
    width: 244px;
    height: 486px;
    right: 0;
    top: 116px;
    z-index: 0;
  }
  .img3{
    position: absolute;
    width: 265px;
    height: 265px;
    left:0;
    bottom:0;
  }
  .content-container{
    display: flex;
    padding-top: 20px;
    .content-left{
      width:400px;
      .title{
        font-size: 24px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #000000;
        line-height: 36px;
        margin-bottom: 40px;
      }
      .content{
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 36px;
      }
    }
    .content-right{
      padding-left: 166px;
      width: calc(100% - 400px);
      position: relative;
      z-index: 999;
      .top,.item{
        background: #FFFFFF;
        height: 280px;
        cursor: pointer;
        position: relative;
        i{
          position: absolute;
          width:32px;
          height: 32px;
          right: 30px;
          bottom: 15px;
          background: url("./img/swap-right.png") no-repeat 0 0;
        }
        h1{
          font-size: 30px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #191B1E;
          line-height: 48px;
          margin-bottom: 24px;
        }
        p{
          width: 100%;
          font-size: 20px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 36px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
        }
      }
      .top{
        padding: 30px;
        width: 100%;
        transition: all .3s;
        &:hover{
          background: url("./img/bj.png") no-repeat 0 0;
          -webkit-background-size: cover;
          background-size: cover;
          transform: translate(0, -10px);
          h1, p{
            color: #FFFFFF;
          }
          i{
            background: url("./img/active-right.png") no-repeat 0 0;
          }
        }
      }
      .item{
        padding:30px;
        width: 49%;
        transition: all .3s;
        &:hover{
          background: url("./img/bj.png") no-repeat 0 0;
          -webkit-background-size: cover;
          background-size: cover;
          transform: translate(0, -10px);
          h1, p{
            color: #FFFFFF;
          }
          i{
            background: url("./img/active-right.png") no-repeat 0 0;
          }
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
      }
    }
  }
}
@media screen and(max-width: 1700px) {
  .tech-container{
    padding: 80px 121px 0;
    .content-container{
      padding-top: 0px;
      .content-left{
        width:360px;
        .title{
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 40px;
        }
        .content{
          font-size: 16px;
          line-height: 36px;
        }
      }
      .content-right{
        padding-left: 166px;
        width: calc(100% - 360px);
        position: relative;
        z-index: 999;
        .top,.item{
          height: 260px;
          h1{
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 20px;
          }
          p{
            font-size: 16px;
          }
        }
        .top{
          padding: 30px;
          width: 100%;
        }
        .item{
          padding:30px;
          width: 49%;
          &:hover{
            background: url("./img/bj.png") no-repeat 0 0;
            -webkit-background-size: cover;
            background-size: cover;
            h1{
              color: #FFFFFF;
            }
            p{
              color: #FFFFFF;
            }
          }
        }
        .bottom{
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
        }
      }
    }
  }
}
@media screen and(max-width: 1600px) {
  .tech-container{
    .content-container{
      .content-right{
        padding-left: 100px;
        width: calc(100% - 360px);
      }
    }
  }
}
@media screen and(max-width: 1300px) {
  .img1, .img2, .img3{
    display: none;
  }
  .tech-container{
    padding: 80px 30px 0;
    .content-container{
      .content-left{
        width: 260px;
        .title{
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .content{
          font-size: 14px;
        }
      }
      .content-right{
        padding-left: 50px;
        width: calc(100% - 260px);
        .top, .item{
          padding: 20px;
          height: 180px;
          img{
            bottom: 5px;
            right: 15px;
          }
          h1{
            font-size: 18px;
            margin-bottom: 10px;
          }
          p{
            font-size: 14px;
            line-height: 24px;
          }
        }
        .bottom{
          display: flex;
          flex-direction: column;
          margin: 0;
        }
        .item{
          width: 100%;
          border-top: 1px solid #eeeeee;
          h1{
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 960px) {
  .tech-container{
    padding: 80px 30px 0;
  }
}
.tech-container-mobile{
  display: none;
}
@media screen and(max-width: 760px) {
  .tech-container{
    display: none;
  }
  .tech-container-mobile{
    display: block;
  }
}
.tech-container-mobile{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F6F9FF;
  padding: 62px 0 0 0;
  .mySwiper{
    padding: 10px 0 15% 0;
    margin-top: 20px;
    height: auto;
  }
  .title{
    font-size: 18px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #000000;
    line-height: 28px;
    margin-bottom: 8px;
    padding: 0 24px;
  }
  .sub-title{
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #191B1E;
    line-height: 28px;
    margin-bottom: 8px;
    padding: 0 24px;
  }
  .content{
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 24px;
    margin-bottom: 8px;
    padding: 0 24px;
  }
  .swiper-button-prev, .swiper-button-next{
    width: 32px;
    height: 32px;
    position: absolute;
    top: initial;
    bottom: 0;
    animation: 0 ;
    -moz-animation: 0;
  }
  .swiper-slide{
    padding: 0;
    width: calc(100% - 100px);
    height: 208px;
    .slide{
      padding: 24px 0;
      background: #FFFFFF;
      width: 100%;
      height: 208px;
      .content{
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
      }
    }
  }
  .swiper-slide:nth-child(1) {
    margin-left: 24px;
  }
  .swiper-slide:nth-child(3) {
    margin-right: 24px !important;
  }
  .swiper-slide-active{
    .slide{
      background: url("./img/t1.png") no-repeat 0 0;
      -webkit-background-size: cover;
      background-size: cover;
      .title, .content {
        color: #FFFFFF;
      }
      .content {
        color: #FFFFFF;
      }
    }

  }
  .swiper-button-prev{
    z-index: 10;
    left: 24px;
    background: url("../../../static/right.png") no-repeat 0 0;
    transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    background-size: cover;
  }
  .swiper-button-next{
    background: url("../../../static/right.png") no-repeat 0 0;
    left: 90px;
    background-size: cover;
  }
  .swiper-button-prev, .swiper-button-next.swiper-button-disabled{
    display: block;
  }
}

