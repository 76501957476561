.banner-container{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
  .video-gif{
    width: 100%;
    height: 100%;
    display: none;
  }
  .video-pc{
    min-width:100%;
    height: 100%;
    object-fit: fill;
    position: relative;
    z-index: -1;
  }
  .content{
    width: 742px;
    height: 742px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -371px;
    margin-top: -371px;
    background: rgba(0, 0, 0, 0.2);
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.04)) 1 1;
    opacity: 1;
    //border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.04)) 1 1;
    //filter: blur(undefinedpx);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    padding: 180px 54px 0 54px;
    .top-title{
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 32px;
    }
    .center-title{
      font-size: 32px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
      letter-spacing: 3px;
      margin-top: 16px;
      .sp{
        background-image:-webkit-linear-gradient(left,#317AFC,#2898f9,#3cbafb);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
      }
    }
    .bottom-title{
      margin-top: 24px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 28px;
    }
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
  display: none;
}
//进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display{
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
//所有控件
video::-webkit-media-controls-enclosure{
  display: none;
}
@media screen and(max-width: 1700px) {
  .banner-container{
    .content{
      width: 642px;
      height: 642px;
      margin-left: -321px;
      margin-top: -321px;
      padding: 140px 40px 0 40px;
    }
  }
}
@media screen and(max-width: 760px) {
  .banner-container{
    .video-gif{
      display: block;
    }
    .video-pc{
      display: none;
    }
    .content{
      width: 414px;
      height: 414px;
      margin: 0;
      left: -20px;
      top: 15%;
      padding: 90px 60px 0;
      .top-title{
        display: none;
      }
      .center-title{
        font-size: 24px;
        font-family: Lato-Bold, Lato;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        letter-spacing: 5px;
        .sp{
          color: #FFFFFF;
          background:none;
          -webkit-text-fill-color: #FFFFFF;
        }
      }
      .bottom-title{
        margin-top: 12px;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 24px;
      }
    }
  }
}
