.container-ProductDetail{
  margin-left: 122px;
  margin-right: 122px;
  padding-bottom: 88px;
  .content{
    margin-top: 64px;
    margin-left: 142px;
    margin-right: 142px;
  }
  .article-title{
    height: 42px;
    font-size: 32px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #191B1E;
    line-height: 42px;
  }
  .article-text{
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 48px;
    margin-top: 16px;
  }
  .summary-title{
    height: 42px;
    font-size: 32px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #317AFC;
    line-height: 42px;
    margin-top: 24px;
  }
  .summary-list{
    .item{
      margin-top: 16px;
      display: flex;
      div:first-child{
        flex-shrink: 0;
        width: 177px;
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #191B1E;
        line-height: 42px;
        padding-left: 33px;
        position: relative;
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 13px;
          left: 3px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #317AFC;
        }
      }
      div:nth-child(2) {
        flex-shrink: 1;
        font-size: 24px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 42px;
      }
    }
  }
}

@media screen and(max-width: 1600px) {
  .container-ProductDetail{
    margin-left: 102px;
    margin-right: 102px;
    padding-bottom: 73px;
    .content{
      margin-top: 53px;
      margin-left: 116px;
      margin-right: 116px;
    }
    .article-title{
      height: 35px;
      font-size: 27px;
      line-height: 35px;
    }
    .article-text{
      font-size: 20px;
      line-height: 40px;
      margin-top: 13px;
    }
    .summary-title{
      height: 35px;
      font-size: 27px;
      line-height: 35px;
      margin-top: 20px;
    }
    .summary-list{
      .item{
        margin-top: 13px;
        div:first-child{
          width: 148px;
          font-size: 20px;
          line-height: 35px;
          padding-left: 28px;
          &:before{
            top: 11px;
            left: 3px;
            width: 13px;
            height: 13px;
          }
        }
        div:nth-child(2) {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}

@media screen and(max-width: 1440px) {
  .container-ProductDetail{
    margin-left: 92px;
    margin-right: 92px;
    padding-bottom: 66px;
    .content{
      margin-top: 48px;
      margin-left: 104px;
      margin-right: 104px;
    }
    .article-title{
      height: 32px;
      font-size: 24px;
      line-height: 31px;
    }
    .article-text{
      font-size: 18px;
      line-height: 36px;
      margin-top: 12px;
    }
    .summary-title{
      height: 32px;
      font-size: 24px;
      line-height: 31px;
      margin-top: 18px;
    }
    .summary-list{
      .item{
        margin-top: 12px;
        div:first-child{
          width: 133px;
          font-size: 18px;
          line-height: 31px;
          padding-left: 25px;
          &:before{
            top: 10px;
            left: 2px;
            width: 12px;
            height: 12px;
          }
        }
        div:nth-child(2) {
          font-size: 18px;
          line-height: 31px;
        }
      }
    }
  }
}

@media screen and(max-width: 1200px) {
  .container-ProductDetail{
    margin-left: 76px;
    margin-right: 76px;
    padding-bottom: 55px;
    .content{
      margin-top: 40px;
      margin-left: 88px;
      margin-right: 88px;
    }
    .article-title{
      height: 26px;
      font-size: 20px;
      line-height: 26px;
    }
    .article-text{
      font-size: 15px;
      line-height: 30px;
      margin-top: 10px;
    }
    .summary-title{
      height: 26px;
      font-size: 20px;
      line-height: 26px;
      margin-top: 15px;
    }
    .summary-list{
      .item{
        margin-top: 10px;
        div:first-child{
          width: 111px;
          font-size: 15px;
          line-height: 26px;
          padding-left: 21px;
          &:before{
            top: 8px;
            left: 2px;
            width: 10px;
            height: 10px;
          }
        }
        div:nth-child(2) {
          font-size: 15px;
          line-height: 26px;
        }
      }
    }
  }
}

@media screen and(max-width: 992px) {
  .container-ProductDetail{
    margin-left: 63px;
    margin-right: 63px;
    padding-bottom: 45px;
    .content{
      margin-top: 33px;
      margin-left: 72px;
      margin-right: 72px;
    }
    .article-title{
      height: 22px;
      font-size: 17px;
      line-height: 22px;
    }
    .article-text{
      font-size: 12px;
      line-height: 25px;
      margin-top: 8px;
    }
    .summary-title{
      height: 22px;
      font-size: 17px;
      line-height: 22px;
      margin-top: 12px;
    }
    .summary-list{
      .item{
        margin-top: 8px;
        div:first-child{
          width: 91px;
          font-size: 12px;
          line-height: 22px;
          padding-left: 17px;
          &:before{
            top: 7px;
            left: 2px;
            width: 8px;
            height: 8px;
          }
        }
        div:nth-child(2) {
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}

@media screen and(max-width: 760px) {
  .container-ProductDetail{
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 40px;
    .content{
      margin-top: 32px;
      margin-left: 0;
      margin-right: 0;
    }
    .article-title{
      height: 24px;
      font-size: 16px;
      line-height: 24px;
    }
    .article-text{
      font-size: 14px;
      line-height: 28px;
      margin-top: 8px;
    }
    .summary-title{
      height: 24px;
      font-size: 16px;
      line-height: 24px;
      margin-top: 32px;
    }
    .summary-list{
      .item{
        margin-top: 24px;
        flex-wrap: wrap;
        div:first-child{
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          padding-left: 16px;
          &:before{
            top: 7px;
            left: 0;
            width: 8px;
            height: 8px;
          }
        }
        div:nth-child(2) {
          font-size: 14px;
          line-height: 28px;
          padding-left: 16px;
        }
      }
    }
  }
}
