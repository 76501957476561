
.join-container{
  width: 100%;
  height: 100%;
  padding: 136px 121px 0;
  background: url("../../static/jionUs/banner-home.png") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
.slog{
  text-align: center;
  color: #FFFFFF;
  .search{
    width: 490px;
    height: 55px;
    border-radius: 118px;
    overflow: hidden;
    margin: 64px auto 0;
    position: relative;
    input{
      border: none;
      border-radius: 118px;
      background: #FFFFFF;
      width: 100%;
      height: 100%;
      padding: 0 64px 0 36px;
      color: #000000;
      outline: none;
      font-family: Roboto-Bold, Roboto;
    }
    span{
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #317AFC;
      position: absolute;
      right: 5px;
      top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
  .ch{
    font-size: 48px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 96px;
    letter-spacing: 4px;
  }
  .en{
    font-size: 14px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    letter-spacing: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      margin: 0 32px;
    }
    .left{
      width: 72px;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 52%, rgba(255, 255, 255, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .right{
      width: 72px;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 52%, rgba(255, 255, 255, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
}

.swiper-container{
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 13px 20px 1px rgba(21, 37, 64, 0.21);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  filter: blur(undefinedpx);
  height: 413px;
  padding: 48px 143px;
  margin-top: 100px;
}
.swiper-left {
  .title-tips{
    font-size: 80px;
    font-family: Roboto-Black, Roboto;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.08);
    line-height: 88px;
    letter-spacing: 16px;
  }
  .title{
    font-size: 32px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    letter-spacing: 3px;
    margin-top: -44px;
  }
}
.swiper-right{
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  .item{
    width: 260px;
    height: 317px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-right: 24px;
    padding:32px;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    &:last-child{
      margin-right: 0;
    }
    .title{
      font-size: 24px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.6);
      line-height: 36px;
    }
    .content{
      font-size: 18px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 28px;
    }
    .time{
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 28px;
      position: absolute;
      left: 32px;
      bottom: 32px;
    }
    &:hover{
      transform: translate(0, -10px);
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 19px 15px 1px rgba(0, 0, 0, 0.15);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid;
      border-image: linear-gradient(225deg, rgba(255, 255, 255, 0.69), rgba(255, 255, 255, 0.2)) 1 1;
      .title, .content, .time{
        color: #FFFFFF;
      }
    }
  }
  .more{
    font-size: 24px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.6);
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      color: #FFFFFF;
    }
  }
}

@media screen and(max-width: 1700px) {
  .join-container{
    padding: 100px;
  }
  .swiper-container{
    padding: 48px 100px;
    margin-top: 80px;
  }
  .swiper-right{
    padding-left: 30px;
    .item{
      width: 230px;
      height: 317px;
    }
  }
}

@media screen and(max-width: 1590px) {
  .join-container{
    padding: 80px;
  }
  .swiper-container{
    padding: 48px 60px;
    margin-top: 60px;
  }
  .swiper-right{
    padding-left: 30px;
    .item{
      width: 230px;
      height: 317px;
    }
  }
}


@media screen and(max-width: 1480px) {
  .join-container{
    padding: 80px;
  }
  .swiper-container{
    padding: 48px 60px;
  }
  .swiper-right{
    padding-left: 20px;
    .item{
      width: 200px;
      height: 317px;
      padding: 20px;
      .title{
        font-size:20px;
      }
    }
  }
}
@media screen and(max-width: 1340px) {
  .swiper-container{
    padding: 48px 30px;
  }
  .swiper-right{
    .item{
      width: 180px;
      height: 317px;
      padding: 20px;
    }
  }
}

@media screen and(max-width: 1180px) {
  .swiper-container{
    padding: 48px 30px;
  }
  .swiper-right{
    .item{
      width: 160px;
      height: 317px;
      padding: 20px;
      .title{
        font-size: 18px;
      }
    }
  }
}

@media screen and(max-width: 1080px) {
  .join-container{
    padding: 100px 0 0 0;
    background: url("../../static/jionUs/banner-home-mobile.png") no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .slog{
    .search{
      height: 40px;
      width: calc(100% - 114px);
      input{
        padding-left:16px;
      }
      span{
        width: 36px;
        height: 36px;
        top: 2px;
        right: 2px;
        img{
          width: 20px;
          height: 20px;
        }
      }
    }
    .ch{
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 4px;
    }
    .en{
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 14px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        margin: 8px 0 0 0;
      }
      .left{
        display: none;
      }
      .right{
        display: none;
      }
    }
  }
  .swiper-left{
    .title{
      font-size: 16px;
    }
  }
  .swiper-container{
    flex-direction: column;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 13px 20px 1px rgba(21, 37, 64, 0.21);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-top:60px;
    .title-tips{
      display: none;
    }
    .title{
      line-height: 80px;
    }
  }
  .swiper-right{
    flex-wrap: wrap;
    padding: 0;
    .item{
      width: 48%;
      margin-right: 0;
      height: 110px;
      padding: 16px;
      margin-bottom: 20px;
      font-size: 16px;
      .title{
        font-size: 14px;
        line-height: 22px;
      }
      .content{
        font-size: 12px;
      }
      .time{
        bottom: 16px;
        left:16px;
      }
    }
  }
}
