.position-list-container {
  .border{
    font-family: Roboto-Regular, Roboto;
  }
  .banner {
    width: 100%;
    position: relative;
    font-size: 0;
    >.h5{
      display: none;
    }
    > img {
      width: 100%;
    }
    .title-warp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .title {
        font-size: 32px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ffffff;
        line-height: 48px;
        letter-spacing: 3px;
      }
      .tips {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        letter-spacing: 3px;
      }
    }
  }
  .filter-warp {
    margin: 0 6.35%;
    border: 1px solid #F3F3F3;
    border-top: 0;
    padding-bottom: 40px;
    .filter-list {
      display: flex;
      align-items: center;
      &.filter-level1-warp{
        padding-top: 40px;

      }
      &.filter-level2-warp{
        padding-top: 32px;

      }
      .filter-level1-title, .filter-level2-title {
        font-size: 18px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #191b1e;
        line-height: 28px;
        letter-spacing: 2px;
        margin:0 43px 0 48px;
      }
      .filter-level1-content, .filter-level2-content {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 32px;
        }
        &.active {
          font-weight: bold;
          color: #317afc;
        }
      }
    }
  }
  .h5-dorw-down{
    display: none;
    justify-content: flex-end;
    margin: 0 25px;
    padding: 12px 0;
    border-bottom: 1px solid #F3F3F3;
    .dropdown{
      width: 72px;
      height: 32px;
      background: #F3F3F3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      .dorp-title{
        height: 22px;
        display: flex;
        >span{
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }
      .icon-img{
        height: 100%;
        width: 8px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 3px;
        >img{
          width: 100%;
          height: 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 1310px) {
  .position-list-container {
    .banner {
      width: 100%;
      position: relative;
      font-size: 0;
      >.h5{
        display: none;
      }
      > img {
        width: 100%;
      }
      .title-warp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .title {
          font-size: 32px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          line-height: 48px;
          letter-spacing: 3px;
        }
        .tips {
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 3px;
        }
      }
    }
    .filter-warp {
      display: none;
      margin: 0 6.35%;
      border: 1px solid #F3F3F3;
      border-top: 0;
      padding-bottom: 40px;
      .filter-list {
        display: flex;
        align-items: center;
        &.filter-level1-warp{
          padding-top: 40px;

        }
        &.filter-level2-warp{
          padding-top: 32px;

        }
        .filter-level1-title, .filter-level2-title {
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #191b1e;
          line-height: 28px;
          letter-spacing: 2px;
          margin:0 43px 0 48px;
        }
        .filter-level1-content, .filter-level2-content {
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 22px;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 32px;
          }
          &.active {
            font-weight: bold;
            color: #317afc;
          }
        }
      }
    }
    .h5-dorw-down{
      display: flex;
      justify-content: flex-end;
      margin: 0 25px;
      padding: 12px 0;
      border-bottom: 1px solid #F3F3F3;
      .dropdown{
        width: 72px;
        height: 32px;
        background: #F3F3F3;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        .dorp-title{
          height: 22px;
          display: flex;
          >span{
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
          }
        }
        .icon-img{
          height: 100%;
          width: 8px;
          display: flex;
          align-items: flex-end;
          padding-bottom: 3px;
          >img{
            width: 100%;
            height: 8px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .position-list-container {
    .banner {
      width: 100%;
      position: relative;
      font-size: 0;
      >.h5{
        display: block;
      }
      >.pc{
        display: none;
      }
      > img {
        width: 100%;
      }
      .title-warp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .title {
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          letter-spacing: 3px;
        }
        .tips {
          font-size: 12px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 3px;
        }
      }
    }
  }
}
.position-overlay{
  width: 102px;
  .ant-dropdown-menu{
    .ant-dropdown-menu-item{
      margin: 13px 0;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
      .active{
        font-size: 14px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #317AFC;
        line-height: 22px;
      }
    }
  }
}
