.company-profile{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F9FF;
  padding: 16vh 122px 13.7vh 122px;
  position: relative;
  .top,
  .center,
  .right-bottom {
    position: absolute;
    z-index: 1;
    &>img{
      width: 100%;
      max-height: 100%;
      vertical-align: bottom;
    }
  }
  .top{
    width: 23.28125%;
    top: 0;
    left: 63.125%;
  }
  .center{
    width: 243px;
    top: 42.59%;
    left: 30.52%;
  }
  .right-bottom{
    width: 13.958%;
    right: 0;
    bottom: 0;
  }
  .company-profile-left{
    width: 0;
    height: 100%;
    flex: 567 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    &>div{
      flex-shrink: 0;
    }
    .page-title{
      font-size: 48px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #191B1E;
      line-height: 56px;
      padding-top: 78px;
      padding-bottom: 48px;
      white-space: nowrap;
    }
    .page-title-translate{
      font-size: 80px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: rgba(49, 122, 252, 0.06);
      height: 240px;
      line-height: 120px;
      letter-spacing: 8px;
      white-space: nowrap;
      position: absolute;
      top: -28px;
      left: 0;
      z-index: -1;
    }
    .tips{
      flex: 3;
      font-size: 18px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 36px;
      padding-right: 7.396vw;
    }
    .left-card{
      flex-grow: 1;
      margin-right: 7.396vw;
      position: relative;
      background-color: #EEF4FF;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .left-bottom{
        position: absolute;
        right: -32px;
        bottom: -22px;
        z-index: -1;
        width: 39.5294%;
      }
      &>div{
        padding: 10px 48px;
      }
      .card-title{
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #000000;
        line-height: 36px;
      }
      .card-text {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        margin-top: 32px;
      }
    }
  }

  .company-profile-list{
    width: 0;
    height: 100%;
    flex: 1109 1 0;
    max-height: 744px;
    display: flex;
    position: relative;
    z-index: 2;
  }
  .company-profile-item{
    width: 0;
    height: 100%;
    flex-grow: 259;
    transition: all 0.2s linear;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    &.hover{
      flex-grow: 543;
    }
    &:last-child {
      margin-right: 0;
    }
    .item-img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(0, 44, 122, 0.5);
      transition: all 0.2s linear;
    }
    .text{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      text-align: center;
      .title{
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
      }
      .name{
        display: inline-block;
        font-size: 32px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 48px;
        letter-spacing: 6px;
        margin-top: 16px;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
      }
      .location{
        display: none;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-top: 24px;
        span{
          display: block;
        }
      }
    }
    &.hover {
      &:before{
        background: rgba(49, 122, 252, 0.92);
      }
      .text{
        .name{
          border-bottom-color: #FFF;
        }
        .location{
          display: block;
        }
      }
    }
  }
}

@media screen and(max-width: 1600px) {
  .company-profile{
    padding-top: 13vh;
    .top{
      width: 20%;
    }
    .center{
      width: 243px;
    }
    .right-bottom{
      width: 16.6875%;
    }
    .company-profile-left{
      flex: 480;
      .page-title-translate{
        font-size: 62px;
        letter-spacing: 6px;
      }
      .tips{
        font-size: 17px;
        line-height: 32px;
        padding-right: 100px;
      }
      .left-card{
        margin-right: 100px;
        .left-bottom{
          right: -56px;
          width: 43.45%;
        }
        &>div{
          padding: 10px 32px;
        }
        .card-text {
          line-height: 28px;
          margin-top: 16px;
        }
      }
    }

    .company-profile-list{
      width: 0;
      flex-grow: 876;
      max-height: 759px;
      display: flex;
      position: relative;
      z-index: 2;
    }
    .company-profile-item{
      width: 0;
      height: 100%;
      flex-grow: 216;
      &.hover{
        flex-grow: 456;
      }
    }
  }
}

@media screen and(max-width: 1440px) {
  .company-profile{
    padding-top: 12vh;
    padding-left: 95px;
    padding-right: 95px;
    .top{
      width: 20%;
      left: 63.4%;
    }
    .center{
      width: 237px;
      top: 34.1975%;
      left: 31.319%;
    }
    .right-bottom{
      width: 18.54%;
      right: 0;
      bottom: 0;
    }
    .company-profile-left{
      width: 0;
      flex: 407;
      .page-title{
        font-size: 32px;
        line-height: 38px;
        padding-top: 3px;
        padding-bottom: 16px;
      }
      .page-title-translate{
        font-size: 52px;
        height: 140px;
        line-height: 70px;
        letter-spacing: 5px;
        top: -10px;
      }
      .tips{
        flex: 6;
        font-size: 16px;
        line-height: 30px;
        padding-right: 80px;
      }
      .left-card{
        margin-right: 80px;
        .left-bottom{
          right: -53px;
          bottom: -21px;
          width: 51.957%;
        }
        &>div{
          padding: 10px 24px;
        }
        .card-text {
          line-height: 28px;
          margin-top: 16px;
        }
      }
    }

    .company-profile-list{
      flex-grow: 789;
      max-height: 759px;
      display: flex;
      flex-wrap: wrap;
    }
    .company-profile-item{
      width: calc(50% - 12px);
      height: calc(50% - 12px);
      order: 2;
      flex-grow: 1;
      margin-right: 0;
      position: relative;
      &:first-child{
        width: 100%;
        margin-bottom: 24px;
      }
      &:nth-child(3){
        margin-left: 24px;
      }
      &.hover{
        flex-grow: 1;
      }
      .text{
        .name{
          margin-top: 16px;
          padding-bottom: 16px;
        }
        .location{
          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and(max-width: 1200px) {
  .company-profile{
    padding-top: 12vh;
    padding-left: 80px;
    padding-right: 80px;
    .top{
      width: 24%;
      left: 63.4%;
    }
    .center{
      width: 237px;
      top: 41.037%;
      left: 31.319%;
    }
    .right-bottom{
      width: 18.54%;
      right: 0;
      bottom: 0;
    }
    .company-profile-left{
      width: 0;
      flex: 407;
      .page-title{
        font-size: 24px;
        line-height: 28px;
      }
      .page-title-translate{
        font-size: 38px;
        height: 104px;
        line-height: 52px;
        letter-spacing: 3px;
      }
      .tips{
        flex: 10;
        font-size: 14px;
        line-height: 28px;
        padding-right: 80px;
      }
      .left-card{
        margin-right: 80px;
        .left-bottom{
          right: -53px;
          bottom: -21px;
          width: 51.957%;
        }
        &>div{
          padding: 5px 18px;
        }
        .card-text {
          line-height: 28px;
          margin-top: 16px;
        }
      }
    }

    .company-profile-list{
      flex-grow: 789;
      max-height: 759px;
      display: flex;
      flex-wrap: wrap;
    }
    .company-profile-item{
      width: calc(50% - 12px);
      height: calc(50% - 12px);
      order: 2;
      flex-grow: 1;
      margin-right: 0;
      position: relative;
      &:first-child{
        width: 100%;
        margin-bottom: 24px;
      }
      &:nth-child(3){
        margin-left: 24px;
      }
      &.hover{
        flex-grow: 1;
      }
      .text{
        .name{
          margin-top: 16px;
          padding-bottom: 16px;
        }
        .location{
          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and(max-width: 992px) {
  .company-profile{
    background: #F6F9FF;
    padding: 72px 0 0 0;
    flex-direction: column;
    .top,
    .center,
    .right-bottom,
    .page-title-translate,
    .left-card{
      display: none !important;
    }
    .company-profile-left{
      width: 100%;
      padding: 0 24px;
      .page-title{
        font-size: 18px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #000000;
        height: auto;
        line-height: 28px;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      .tips{
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 28px;
        padding-right: 0;
        margin-top: 8px;
      }
    }
    .company-profile-list{
      width: 100%;
      flex-direction: column;
      max-height: none;
      padding: 8px 24px 70px 24px;
    }
    .company-profile-item{
      height: 0 !important;
      width: 100% !important;
      margin-right: 0;
      border-radius: 0;
      overflow: hidden;
      flex-grow: 1 !important;
      margin-left: 0 !important;
      margin-bottom: 0 !important;

      &.hover{
        flex-grow: 2 !important;
      }

      &:not(:first-child) {
        margin-top: 12px;
      }
      .text{
        .title{
          font-size: 14px;
        }
        .name{
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 9px;
          padding-bottom: 4px;
          border-bottom-width: 1px;
          margin-top: 0;
        }
        .location{
          font-size: 14px;
          line-height: 22px;
          margin-top: 4px;
          span{
            display: block;
          }
        }
      }
    }
  }
}

@media screen and(max-width: 850px) {
  .company-profile{
    padding-top: 62px;
  }
  .company-profile .company-profile-left .tips{
    line-height: 22px;
  }
}
