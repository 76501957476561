.dev-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  .mySwiper{
    margin-top: -8px;
    background: none;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #E7E7E7;
      top: 8px;
    }
  }
}

.t2{
  display: none;
}

.dev-container{
  .swiper-button-prev, .swiper-button-next{
    width: 32px;
    height: 32px;
    position: absolute;
    top: initial;
    bottom: 180px;
    animation: 0 ;
    -moz-animation: 0;
  }
  .swiper-slide{
    padding: 0;
    //width: 826px !important;
    display: flex;
    align-items: flex-start;
    background: none;
    .slide{
      width: 100%;
      position: relative;
      background: none;
      &:after{
        content: '';
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px 1px rgba(49, 122, 252, 0.25);
        opacity: 1;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 999;
      }
      .time{
        text-align: center;
        font-size: 18px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #000000;
        margin: 36px 0 24px 0;
        line-height: 36px;
      }
      .t1{
        display: block;
        width: 100%;
      }
      .img{
        position: relative;
        display: flex;
        justify-content: center;
        span{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 44, 122, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .address{
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #000000;
        line-height: 19px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          display: block;
          width: 8px;
          height: 8px;
          background: #000000;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
  .swiper-slide-active{
    .slide{
      .time{
        color: #317AFC;
      }
      .img{
        span{
          display: none;
        }
        .span1{
          display: flex;
          img{
            width: 80%;
          }
        }
      }
    }
  }
  .swiper-button-prev{
    z-index: 10;
    left: 122px;
    background: url("./img/right.png") no-repeat 0 0;
    transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
  }
  .swiper-button-next{
    background: url("./img/right.png") no-repeat 0 0;
    left: 200px;
  }
  .swiper-button-prev, .swiper-button-next.swiper-button-disabled{
    display: block;
  }

}

@media screen and(max-width: 1700px) {
  .dev-container{
    padding-top: 80px;
  }
}
@media screen and(max-width: 760px) {
  .dev-container{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F6F9FF;
    padding-top: 62px;
    .mySwiper{
      padding: 10px 0 15% 0;
      margin-top: 20px;
      height: auto;
      &:after{
        display: none;
      }
    }

    .swiper-slide{
      padding: 0;
      display: flex;
      align-items: flex-start;
      background: none;
      .slide{
        width: 100%;
        position: relative;
        background: none;
        &:after{
          display: none;
        }
        .time{
          font-size: 12px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 18px;
          margin: 0 0 20px 0;
        }
        .t1{
          display: none;
        }
        .t2{
          display: block;
          width:100%;
          //height: 312px;
        }
        .address{
          font-size: 13px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
        }
        .span1{
          display: flex;
          img{
            width: 60%;
          }
        }
      }
    }
    .swiper-slide-active{
      .slide{
        .time{
          color: #317AFC;
        }
      }
    }
    .swiper-button-prev{
      z-index: 10;
      left: 24px;
      background: url("../../../static/right.png") no-repeat 0 0;
      transform:rotate(180deg);
      -moz-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
    }
    .swiper-button-next{
      background: url("../../../static/right.png") no-repeat 0 0;
      left: 90px;
    }
    .swiper-button-prev, .swiper-button-next{
      bottom: 0;
      background-size: cover;
    }
  }
}
