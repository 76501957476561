.components-back{
  padding-top: 120px;
  .button{
    display: inline-flex;
    align-items: center;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #191B1E;
    line-height: 42px;
    cursor: pointer;
  }
  img{
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}

@media screen and(max-width: 1600px) {
  .components-back{
    //padding-top: 100px;
    .button{
      font-size: 23px;
      line-height: 35px;
    }
    img{
      width: 27px;
      height: 27px;
      margin-right: 7px;
    }
  }
}

@media screen and(max-width: 1440px) {
  .components-back{
    //padding-top: 92px;
    .button{
      font-size: 21px;
      line-height: 31px;
    }
    img{
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }
}

@media screen and(max-width: 1200px) {
  .components-back{
    //padding-top: 82px;
    .button{
      font-size: 18px;
      line-height: 26px;
    }
    img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

@media screen and(max-width: 992px) {
  .components-back{
    //padding-top: 82px;
    .button{
      font-size: 14px;
      line-height: 22px;
    }
    img{
      width: 17px;
      height: 17px;
      margin-right: 4px;
    }
  }
}
@media screen and(max-width: 850px) {
  .components-back{
    padding-top: 62px;
  }
}
@media screen and(max-width: 760px) {
  .components-back{
    padding-top: 52px;
    .button{
      font-size: 14px;
      line-height: 22px;
    }
    img{
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
