.container-News{
  .item{
    padding: 88px 0;
    margin-left: 122px;
    margin-right: 122px;
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid #E7E7E7;
    }
    &>div,
    &>img{
      width: 0;
      flex: 1;
      box-sizing: border-box;
    }
    &>div:first-child{
      margin-right: 24px;
    }
    &>div:first-child>div,
    &>div:first-child>a{
      margin-right: 142px;
    }
    &.reverse{
      flex-direction: row-reverse;
      &>div:first-child{
        margin-right: 0;
      }
      &>div:first-child>div,
      &>div:first-child>a{
        margin-left: 142px;
        margin-right: 0;
      }
      &>img {
        margin-right: 24px;
      }
    }
  }
  .date{
    width: 118px;
    height: 118px;
    background: #EEF4FF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span{
      display: block;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 36px;
      &:first-child{
        font-size: 36px;
        margin-bottom: 8px;
      }
    }
  }
  .title{
    font-size: 28px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #191B1E;
    line-height: 42px;
    margin-top: 48px;
  }
  .text{
    font-size: 18px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 36px;
    margin-top: 24px;
  }
  .button{
    width: 104px;
    height: 48px;
    background: #317AFC;
    margin-top: 56px;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s linear;
    img{
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
    &:hover{
      background: #085ffa;
    }
  }
  .item-img{
    align-self: center;
    display: flex;
    align-items: center;
  }
}
.content-pc{
  display: block;
}
.content-mobile{
  display: none;
}

@media screen and(max-width: 1600px) {
  .container-News{
    .item{
      padding: 73px 0;
      margin-left: 102px;
      margin-right: 102px;
      &>div:first-child{
        margin-right: 20px;
      }
      &>div:first-child>div,
      &>div:first-child>a{
        margin-right: 119px;
      }
      &.reverse{
        &>div:first-child>div,
        &>div:first-child>a{
          margin-left: 119px;
        }
        &>img {
          margin-right: 20px;
        }
      }
    }
    .date{
      width: 98px;
      height: 98px;
      span{
        font-size: 20px;
        line-height: 30px;
        &:first-child{
          font-size: 30px;
          margin-bottom: 7px;
        }
      }
    }
    .title{
      font-size: 23px;
      line-height: 35px;
      margin-top: 40px;
    }
    .text{
      font-size: 15px;
      line-height: 30px;
      margin-top: 20px;
    }
    .button{
      width: 87px;
      height: 40px;
      margin-top: 47px;
      font-size: 13px;
      line-height: 23px;
      img{
        width: 13px;
        height: 13px;
        margin-left: 7px;
      }
    }
  }
}

@media screen and(max-width: 1440px) {
  .container-News{
    .item{
      padding: 66px 0;
      margin-left: 92px;
      margin-right: 92px;
      &>div:first-child{
        margin-right: 18px;
      }
      &>div:first-child>div,
      &>div:first-child>a{
        margin-right: 107px;
      }
      &.reverse{
        &>div:first-child>div,
        &>div:first-child>a{
          margin-left: 107px;
        }
        &>img {
          margin-right: 18px;
        }
      }
    }
    .date{
      width: 89px;
      height: 89px;
      span{
        font-size: 18px;
        line-height: 27px;
        &:first-child{
          font-size: 27px;
          margin-bottom: 6px;
        }
      }
    }
    .title{
      font-size: 18px;
      line-height: 27px;
      margin-top: 36px;
    }
    .text{
      font-size: 14px;
      line-height: 27px;
      margin-top: 18px;
    }
    .button{
      width: 78px;
      height: 36px;
      margin-top: 42px;
      font-size: 12px;
      line-height: 20px;
      img{
        width: 12px;
        height: 12px;
        margin-left: 6px;
      }
    }
  }
}

@media screen and(max-width: 1200px) {
  .container-News{
    .item{
      padding: 54px 0;
      margin-left: 76px;
      margin-right: 76px;
      &>div:first-child{
        margin-right: 15px;
      }
      &>div:first-child>div,
      &>div:first-child>a{
        margin-right: 89px;
      }
      &.reverse{
        &>div:first-child>div,
        &>div:first-child>a{
          margin-left: 89px;
        }
        &>img {
          margin-right: 15px;
        }
      }
    }
    .date{
      width: 74px;
      height: 74px;
      span{
        font-size: 15px;
        line-height: 23px;
        &:first-child{
          font-size: 23px;
          margin-bottom: 5px;
        }
      }
    }
    .title{
      font-size: 18px;
      line-height: 26px;
      margin-top: 30px;
    }
    .text{
      font-size: 11px;
      line-height: 23px;
      margin-top: 15px;
    }
    .button{
      width: 65px;
      height: 30px;
      margin-top: 35px;
      font-size: 10px;
      line-height: 17px;
      img{
        width: 10px;
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@media screen and(max-width: 992px) {
  .container-News{
    .item{
      padding: 45px 0;
      margin-left: 63px;
      margin-right: 63px;
      &>div:first-child{
        margin-right: 12px;
      }
      &>div:first-child>div,
      &>div:first-child>a{
        margin-right: 74px;
      }
      &.reverse{
        &>div:first-child>div,
        &>div:first-child>a{
          margin-left: 74px;
        }
        &>img {
          margin-right: 12px;
        }
      }
    }
    .date{
      width: 61px;
      height: 61px;
      span{
        font-size: 12px;
        line-height: 19px;
        &:first-child{
          font-size: 19px;
          margin-bottom: 4px;
        }
      }
    }
    .title{
      font-size: 14px;
      line-height: 22px;
      margin-top: 25px;
    }
    .text{
      font-size: 9px;
      line-height: 19px;
      margin-top: 12px;
    }
    .button{
      width: 54px;
      height: 25px;
      margin-top: 29px;
      font-size: 8px;
      line-height: 14px;
      img{
        width: 8px;
        height: 8px;
        margin-left: 4px;
      }
    }
  }
}

@media screen and(max-width: 760px) {
  .content-pc{
    display: none;
  }
  .content-mobile{
    display: block;
  }
  .container-News{
    .item{
      display: block;
      padding: 40px 0;
      margin-left: 24px;
      margin-right: 24px;
      //border-bottom: none !important;
      border-bottom-color: #F3F3F3;
      &>div,
      &>img{
        width: 100%;
      }
    }
    .date{
      width: auto;
      height: 22px;
      text-align: left;
      margin-top: 8px;
      background-color: transparent;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 22px;
      display: block;
    }
    .title{
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
      color: #000000;
    }
    .text{
      font-size: 14px;
      line-height: 28px;
      margin-top: 16px;
    }
    .button{
      display: none;
    }
    .item-img{
      margin-top: 16px;
    }
     .components-head-img .head-img-text .head-img-title{
      letter-spacing: 19px;
      padding-left: 19px;
    }
  }
}
