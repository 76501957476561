.title-container {
  text-align: center;
  padding: 0 0 30px 0;

  .title-tips {
    font-size: 80px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #E7F0FF;
    line-height: 94px;
    letter-spacing: 16px;
    margin-bottom: 0;
  }

  .title {
    font-size: 48px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #000000;
    line-height: 56px;
    margin-top: -75px;
    margin-bottom: 0;
  }
}

@media screen and(max-width: 1700px) {
  .title-container {
    .title-tips {
      font-size: 60px;
      line-height: 94px;
      letter-spacing: 16px;
    }

    .title {
      font-size: 38px;
      line-height: 56px;
      margin-top: -75px;
    }
  }
}

@media screen and(max-width: 1600px) {
  .title-container {
    .title-tips {
      font-size: 40px;
    }
    .title {
      font-size: 30px;
    }
  }
}
@media screen and(max-width: 992px) {
  .title-container {
    .title-tips {
      display: none;
    }
    .title{
      font-size: 24px;
      line-height: 28px;
      margin-top:20px;
      text-align: center;
    }
  }
}
@media screen and(max-width: 760px) {
  .title-container{
    padding: 0 0 0 24px;
    border: 0;
    margin: 0;
    .title-tips{
      display: none;
    }
    .title{
      font-size: 18px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      margin-bottom: 15px;
      margin-top: 0;
      text-align: left;
    }
  }
}
