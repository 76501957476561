.information-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F6F9FF;
  padding: 100px 122px 0 122px;
  position: relative;
  // padding-bottom: 133px;
  .left-top{
    position: absolute;
    width: 266px;
    height: 266px;
    top: 0;
    left: 0;
    >img{
      width: 100%;
      max-height: 100%;
    }
  }
  .left-bottom{
    position: absolute;
    width: 176px;
    height: 176px;
    bottom: 80px;
    left: 0;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  .right-top{
    position: absolute;
    top: 0;
    right: 0;
    width: 145px;
    height: 176px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  .right-bottom{
    position: absolute;
    bottom: 0;
    right: 60px;
    width: 270px;
    height: 116px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  .information-swiper{
    padding:20px 0 116px 0;

    .swiper-wrapper{
      // height: auto;
      max-height: 100%;
      padding-top: 10px;
    }

  }
  .swiper-item{
    // background: #ccc;
    transition: all .3s;
    cursor: pointer;
    background: #ffffff;
    .item-warp{
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      .item-img{
        width: 100%;
        // height: 328px;
        >img{
          width: 100%;
          max-height: 100%;
        }
      }
      .item-time{
        margin: 20px 0 0 0;
        width: 100%;
        height: 32px;
        font-size: 14px;
        font-family: Supermercado-Regular, Supermercado;
        font-weight: 400;
        color: #317AFC;
        line-height: 32px;
        // text-align: center;
        .time{
          width: 140px;
          height: 100%;
          background: url(./img/timeBg.png) no-repeat;
          background-size: 100% 100%;
          padding: 0 32px;

        }
      }
      .item-title{
        margin-top: 20px;
        padding: 0 32px;
        font-size: 20px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #191B1E;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        //text-overflow: -o-ellipsis-lastline;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
      }
      .item-content{
        height: 84px;
        margin-top: 24px;
        padding: 0 32px 0;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 28px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .jump{
        position: absolute;
        right: 3%;
        bottom: 2%;
        width: 16px;
        height: 16px;
        background: url(./img/right.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    &:hover{
      transform: translate(0, -10px);
      background: #317AFC;
      box-shadow: 0px 14px 20px -8px rgba(49, 122, 252, 0.4);
      .item-time{
        color: #FFFFFF;
        .time{
          background: url(./img/activeTimeBg.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .item-title,.item-content{
        color: #fff;
      }
      .jump{
        background: url(./img/active-right.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

@media screen and(max-width: 1700px) {
  .information-container{
    padding-top: 80px;
  }
}
@media screen and (max-width: 1440px) {
  .information-container{
    padding: 72px 6.35% 0;
    // padding-bottom: 133px;
    .left-top{
      width: 266px;
      height: 266px;
    }
    .left-bottom{
      width: 176px;
      height: 176px;
      bottom: 80px;
      left: 0;
    }
    .right-top{
      top: 0;
      right: 0;
      width: 145px;
      height: 176px;
    }
    .right-bottom{
      bottom: 0;
      right: 60px;
      width: 270px;
      height: 116px;
    }
    .information-swiper{
      padding: 0;
      .swiper-wrapper{
        height: auto;
        max-height: 100%;
      }
    }
    .swiper-item{
      .item-warp{
      padding-bottom: 10%;

        .item-time{
          margin: 22px 0 0 0;
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          // text-align: center;
          .time{
            width: 140px;
            padding: 0 32px;
          }
        }
        .item-title{
          margin-top: 14px;
          padding: 0 22px;
          font-size: 20px;
          line-height: 32px;
          height: 32px;
        }
        .item-content{
          margin-top: 18px;
          padding: 0 32px 7%;
          font-size: 14px;
          line-height: 28px;
        }
        .jump{
          right: 3%;
          bottom: 2%;
          width: 16px;
          height: 16px;
        }
      }
      &:hover{
        transform: translate(0, -10px);
      }
    }
  }
}
@media screen and (min-width: 851px) and (max-width: 1300px) {
  .information-container{
    padding: 72px 6.35% 0;
    // padding-bottom: 133px;
    .left-top{
      width: 266px;
      height: 266px;
    }
    .left-bottom{
      width: 176px;
      height: 176px;
      bottom: 80px;
      left: 0;
    }
    .right-top{
      top: 0;
      right: 0;
      width: 145px;
      height: 176px;
    }
    .right-bottom{
      bottom: 0;
      right: 60px;
      width: 270px;
      height: 116px;
    }
    .information-swiper{
      padding: 0 0 6% 0;
      height: auto;
      .swiper-wrapper{
        height: auto;
        max-height: 100%;
      }
      .swiper-button-prev{
        left: 0;
        background: url("../../../static/right.png") no-repeat 0 0;
        transform:rotate(180deg);
        -moz-transform: rotate(180deg);
      }
      .swiper-button-next{
        background: url("../../../static/right.png") no-repeat 0 0;
        left: 66px;
      }
      .swiper-button-prev, .swiper-button-next{
        width: 32px;
        height: 32px;
        position: absolute;
        top: initial;
        bottom: 0;
        animation: 0 ;
        -moz-animation: 0;
        z-index: 999999;
        background-size: cover;
      }
      .swiper-button-prev, .swiper-button-next.swiper-button-disabled{
        display: block;
      }
    }
    .swiper-item{
      .item-warp{
      padding-bottom: 10%;

        .item-time{
          margin: 22px 0 0 0;
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          // text-align: center;
          .time{
            width: 140px;
            padding: 0 32px;
          }
        }
        .item-title{
          margin-top: 14px;
          padding: 0 22px;
          font-size: 20px;
          line-height: 32px;
          height: 32px;
        }
        .item-content{
          margin-top: 18px;
          padding: 0 32px 7%;
          font-size: 14px;
          line-height: 28px;
        }
        .jump{
          right: 3%;
          bottom: 2%;
          width: 16px;
          height: 16px;
        }
      }
      &:hover{
        transform: translate(0, -10px);
      }
    }
  }
}
@media screen and(max-width: 992px) {
  .information-container{
    .left-top,.left-bottom,.right-top,.right-bottom{
      display: none;
    }
  }
}
@media screen and (max-width: 850px) {
  .information-container{
    padding: 62px 0 0 0;
    // padding-bottom: 133px;
    .information-swiper{
      padding: 10px 24px 15% 24px;
      margin-top: 20px;
      height: auto;
      .swiper-wrapper{
        height: auto;
        max-height: 100%;
        //padding-right: 24px;
        width: auto;
      }
      .swiper-slide:nth-child(1) {
        //margin-left: 24px;
      }
      .swiper-slide:nth-child(3) {
        //margin-right: 24px !important;
      }

      .swiper-button-prev{
        left: 24px;
        background: url("../../../static/right.png") no-repeat 0 0;
        transform: rotate(180deg);
      }
      .swiper-button-next{
        background: url("../../../static/right.png") no-repeat 0 0;
        left: 90px;
      }
      .swiper-button-prev, .swiper-button-next{
        width: 32px;
        height: 32px;
        position: absolute;
        top: initial;
        bottom: 0;
        animation: 0 ;
        -moz-animation: 0;
        z-index: 999999;
        background-size: cover;
      }
      .swiper-button-prev, .swiper-button-next.swiper-button-disabled{
        display: block;
      }
    }
    .swiper-item{
      .item-warp{
      padding-bottom: 10%;

        .item-time{
          margin: 22px 0 0 0;
          height: 20px;
          font-size: 12px;
          line-height: 18px;
          // text-align: center;
          .time{
            width: 140px;
            padding: 0 32px;
          }
        }
        .item-title{
          margin-top: 14px;
          padding: 0 22px;
          font-size: 16px;
          line-height: 24px;
          height: 24px;
        }
        .item-content{
          margin-top: 18px;
          padding: 0 22px 7%;
          font-size: 14px;
          line-height: 28px;
        }
        .jump{
          right: 3%;
          bottom: 2%;
          width: 16px;
          height: 16px;
        }
      }
      &:hover{
        transform: translate(0, -10px);
      }
    }
  }
}
