.jion-us-container{
  .banner{
    width: 100%;
    position: relative;
    font-size: 0;
    >.h5{
      display: none;
    }
    > img {
      width: 100%;
    }
    .title-warp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .title {
        font-size: 32px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ffffff;
        line-height: 48px;
        letter-spacing: 3px;
      }
      .tips {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        letter-spacing: 3px;
      }
    }
  }
  .form-warp{
    width: 50%;
    max-width: 1110px;
    min-width: 327px;
    margin: 0 auto;
    // padding: 0 6.35%;
    .title{
      margin: 40px 0 24px 0;
      font-size: 16px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #444445;
      >span{
        color: #317AFC;
      }
    }
    .from{
      .ant-form-item{
        font-size: 18px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #444445;
        line-height: 22px;
        .ant-form-item-control{
          .ant-form-item-control-input{
            .ant-form-item-control-input-content{
              .ant-upload-select{
                width: 100%;
              }
              >.ant-input{
                font-size: 18px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #797979;
                line-height: 44px;
                border-radius: 8px;
              }
              >.ant-select{
                .ant-select-selection-search{
                  >input{
                    height: 54px;
                    line-height: 54px;
                  }
                }
                .ant-select-selector{
                  height: 54px;
                  border-radius: 8px;
                  .ant-select-selection-placeholder{
                    line-height: 54px;
                  }
                  .ant-select-selection-item{
                    line-height: 54px;
                  }
                }
              }
            }
          }
          .upload{
            width: 30%;
            min-width: 96px;
            height: 54px;
            background: #E5E5E5;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 0;
            color: #444445;
            &:hover{
              background: #E5E5E5;
              color: #444445;
            }
          }
          .upload-tips{
            margin-top: 4px;
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #808080;
          }
        }
      }
    }
  }
  .button-submit{
    .ant-form-item-control-input-content{
      display: flex;
      justify-content: flex-end;
      padding-bottom: 150px;
      .submitBtn{
        width: 118px;
        height: 48px;
        background: #317AFC;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .jion-us-container{
    width: 100%;
    height: 100%;
    .banner {
      width: 100%;
      position: relative;
      font-size: 0;
      >.h5{
        display: block;
      }
      >.pc{
        display: none;
      }
      > img {
        width: 100%;
      }
      .title-warp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .title {
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          letter-spacing: 3px;
        }
        .tips {
          font-size: 12px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 3px;
        }
      }
    }
    .form-warp{
      width: 50%;
      max-width: 1110px;
      min-width: 327px;
      margin: 0 auto;
      // padding: 0 140px;
      .title{
        margin: 90px 0 32px 0;
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #444445;
        >span{
          color: #317AFC;
        }
      }
      .from{
        .ant-form-item{
          font-size: 18px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #444445;
          line-height: 22px;
          .ant-form-item-control{
            .ant-form-item-control-input{
              .ant-form-item-control-input-content{
                .ant-upload-select{
                  width: 100%;
                }
                >.ant-input{
                  font-size: 18px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #797979;
                  line-height: 44px;
                  border-radius: 8px;
                }
                >.ant-select{
                  .ant-select-selection-search{
                    >input{
                      height: 54px;
                      line-height: 54px;
                    }
                  }
                  .ant-select-selector{
                    height: 54px;
                    border-radius: 8px;
                    .ant-select-selection-placeholder{
                      line-height: 54px;
                    }
                    .ant-select-selection-item{
                      line-height: 54px;
                    }
                  }
                }
              }
            }
            .upload{
              width: 30%;
              min-width: 96px;
              height: 54px;
              background: #E5E5E5;
              border-radius: 8px 8px 8px 8px;
              opacity: 1;
              border: 0;
              color: #444445;
              &:hover{
                background: #E5E5E5;
                color: #444445;
              }
            }
            .upload-tips{
              margin-top: 4px;
              font-size: 12px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #808080;
            }
          }
        }
      }
    }
    .button-submit{
      .ant-form-item-control-input-content{
        display: flex;
        justify-content: center;
        padding-bottom: 150px;
        .submitBtn{
          width: 80%;
          height: 48px;
          background: #317AFC;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}